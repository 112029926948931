import {useState} from 'react';
import localFont from 'next/font/local';

import PageLayout from '@/layout/PageLayout';
import * as Sentry from '@sentry/react';

import AppProviders from '@/contexts/AppProviders';
import useClientOfflineControl from '@/hooks/useClientOfflineControl';
import {useFirebaseUser} from '@/hooks/useFirebaseUser';
import {errorMonitoringService} from '@/services/error/ErrorMonitoringService';

import ErrorPage from '@/components/ErrorPage/ErrorPage';
import AppHead from '@/components/heads/AppHead';
import PageInitiator from '@/components/PageInitiator';

import type {Dispatch, SetStateAction} from 'react';

import 'primereact/resources/primereact.min.css';

import {GTMScript} from '@/components/heads/GTM';

import '../styles/globals.scss';
import '../styles/icomoon.css';

import useServiceWorkerUpdate from '@/hooks/useServiceWorkerUpdate';

import type {NextPageWithLayout} from '@/types/generics';
import type {User} from 'firebase/auth';
import type {AppProps} from 'next/app';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};
export type SessionStatesProps = {
  fbUser: User | null;
  setFBUserOnce: boolean;
  userDidEnterSoftPin: boolean;
  setUserDidEnterSoftPin: Dispatch<SetStateAction<boolean>>;
};

export type PageComponentPropType<T> = NextPageWithLayout<T & SessionStatesProps>;

const primaryFont = localFont({
  src: [
    {
      path: '../public/fonts/UCityPro-Regular.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/UCityPro-Bold.otf',
      weight: '700',
    },
  ],
});

const secondaryFont = localFont({
  src: '../public/fonts/PPMonumentExtended-Black.ttf',
  variable: '--secondary-font',
});

export default function MyApp({Component, pageProps}: AppPropsWithLayout) {
  // Session long states
  const fbUserProps = useFirebaseUser();
  const [userDidEnterSoftPin, setUserDidEnterSoftPin] = useState(false);

  useClientOfflineControl();
  useServiceWorkerUpdate();

  // Use the layout defined at the page level, if any
  const getLayout =
    Component.getLayout ??
    (page => (
      <PageInitiator {...page.props}>
        <PageLayout>{page}</PageLayout>
      </PageInitiator>
    ));

  return (
    <Sentry.ErrorBoundary fallback={Fallback} onError={errorMonitoringService.logError}>
      <div className={`${primaryFont.className} ${secondaryFont.variable}`}>
        <AppHead />
        {/* Google tag manager should be out heads*/}
        <GTMScript />
        <AppProviders pageProps={pageProps}>
          {getLayout(
            <Component
              {...{
                userDidEnterSoftPin,
                setUserDidEnterSoftPin,
                ...fbUserProps,
                ...pageProps,
              }}
            />,
          )}
        </AppProviders>
      </div>
    </Sentry.ErrorBoundary>
  );
}

function Fallback() {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <ErrorPage
      buttonLabel="Try Again"
      description="Please try again or contact the support in case this issue continues."
    />
  );
}
