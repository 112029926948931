import Image from 'next/image';

import type {MouseEventHandler} from 'react';

// Icons in public/images folder not our in-house icons library
export type availableIcons =
  | 'Bulletpoint'
  | 'Google2FAnew'
  | 'Google'
  | 'search'
  | 'Kasta_ShortIcon_Indigo_RGB'
  | 'visa'
  | 'mastercard';

export interface IconSvgProps {
  name: availableIcons;
  role?: string;
  className?: string;
  size?: number;
  onClick?: MouseEventHandler;
}

const IconSvg = ({name, role, className, size = 24, onClick}: IconSvgProps) => (
  <Image
    src={`/images/${name}.svg`}
    height={size}
    width={size}
    alt={`${name} icon`}
    className={className}
    onClick={onClick}
    role={role || `${name}-icon`}
    aria-hidden="true"
  />
);

export default IconSvg;
