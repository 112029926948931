import {environments} from '@/constants';

class ConfigService {
  getEnvironment() {
    if (process.env.NEXT_PUBLIC_GRAPHQL_API_URL?.includes('prod')) {
      return environments.PRODUCTION;
    } else {
      return environments.STAGING;
    }
  }
  getEnvironmentTag() {
    return configService.getEnvironment() === environments.STAGING ? 'testnet' : 'prod';
  }
  getIntergiroCredentials() {
    return process.env.NEXT_PUBLIC_INTERGIRO_KEY;
  }
  getIntergiroScript() {
    return process.env.NEXT_PUBLIC_INTERGIRO_SCRIPT;
  }
}

export const configService = new ConfigService();
