import {zendeskService} from '@/services/support/ZendeskService';
import {tracking} from '@/services/tracking/TrackingService';

export const handleContactSupport = () => {
  try {
    zendeskService.openMessenger();
    tracking.logEvent(tracking.events.open_chat_support());
  } catch (error: any) {
    tracking.logError({
      error_message: `handleContactSupport > ${error?.message}`,
      error_level: 'Critical',
      error_message_id: 'kasta_log_error',
    });
  }
};
