import {ERROR_MESSAGE_ID as PossibleCodes} from '@/types/tracking';

export class InternalError extends Error {
  code: PossibleCodes;
  level = 'Critical' as const;

  constructor({message, code}: {message: string; code: PossibleCodes}) {
    super(message);
    this.name = 'InternalError';
    this.code = code;
    Object.setPrototypeOf(this, InternalError.prototype);
  }
}
