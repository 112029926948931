import {gql} from '@apollo/client';

export const userUpdatedSubscription = gql(/* GraphQL */ `
  subscription publishedUserChanges {
    publishedUserChanges {
      id
      firstName
      lastName
      email
      phoneNumber
      kastaName
      telegramName
      address {
        street
        aptNumber
        city
        postalCode
        country
      }
      referrerCode
      kyc {
        status
        level
        rejectReason
        pending {
          status
          level
          rejectReason
        }
        history {
          status
          level
          rejectReason
          updatedAt
        }
      }
      twoFactorAuth {
        isEnabled
        createdAt
        disabledAt
      }
      deviceToken
      deviceAppInstanceId
      deviceType
      createdAt
      updatedAt
      cashAccountStatus
    }
  }
`);

export const accountUpdatedSubscription = gql(/* GraphQL */ `
  subscription publishedUserAccount {
    publishedUserAccount {
      account {
        balances {
          BNB
          BTC
          BUSD
          DOT
          ETH
          EUR
          KASTA
          POL
          USDC
          USDT
          ADA
          DOT
        }
      }
    }
  }
`);

export const exchangeRatesUpdatedSubscription = gql(/* GraphQL */ `
  subscription publishedExchangeRates {
    publishedExchangeRates {
      ADABNB
      ADABTC
      ADABUSD
      ADADOT
      ADAETH
      ADAEUR
      ADAKASTA
      ADAPOL
      ADAUSD
      ADAUSDC
      ADAUSDT

      BNBADA
      BNBBTC
      BNBBUSD
      BNBDOT
      BNBETH
      BNBEUR
      BNBKASTA
      BNBPOL
      BNBUSD
      BNBUSDC
      BNBUSDT

      BTCADA
      BTCBNB
      BTCBUSD
      BTCDOT
      BTCETH
      BTCEUR
      BTCKASTA
      BTCPOL
      BTCUSD
      BTCUSDC
      BTCUSDT

      BUSDADA
      BUSDBNB
      BUSDBTC
      BUSDDOT
      BUSDETH
      BUSDEUR
      BUSDKASTA
      BUSDPOL
      BUSDUSD
      BUSDUSDC
      BUSDUSDT

      DOTADA
      DOTBNB
      DOTBTC
      DOTBUSD
      DOTETH
      DOTEUR
      DOTKASTA
      DOTPOL
      DOTUSD
      DOTUSDC
      DOTUSDT

      ETHADA
      ETHBNB
      ETHBTC
      ETHBUSD
      ETHDOT
      ETHEUR
      ETHKASTA
      ETHPOL
      ETHUSD
      ETHUSDC
      ETHUSDT

      EURADA
      EURBNB
      EURBTC
      EURBUSD
      EURDOT
      EURETH
      EURKASTA
      EURPOL
      EURUSD
      EURUSDC
      EURUSDT

      KASTAADA
      KASTABNB
      KASTABTC
      KASTABUSD
      KASTADOT
      KASTAETH
      KASTAEUR
      KASTAPOL
      KASTAUSD
      KASTAUSDC
      KASTAUSDT

      POLADA
      POLBNB
      POLBTC
      POLBUSD
      POLDOT
      POLETH
      POLEUR
      POLKASTA
      POLUSD
      POLUSDC
      POLUSDT

      USDADA
      USDBNB
      USDBTC
      USDBUSD
      USDDOT
      USDETH
      USDEUR
      USDKASTA
      USDPOL
      USDUSDC
      USDUSDT

      USDCADA
      USDCBNB
      USDCBTC
      USDCBUSD
      USDCDOT
      USDCETH
      USDCEUR
      USDCKASTA
      USDCPOL
      USDCUSD
      USDCUSDT

      USDTADA
      USDTBNB
      USDTBTC
      USDTBUSD
      USDTDOT
      USDTETH
      USDTEUR
      USDTKASTA
      USDTPOL
      USDTUSD
      USDTUSDC
    }
  }
`);

export const debitCardUpdatedSubscription = gql(/* GraphQL */ `
  subscription publishedCardUpdates {
    publishedCardUpdates {
      userId
      card {
        id
        displayName
        brand
        type
        status
        expMonth
        expYear
        last4
        feesTransactionIds
      }
    }
  }
`);
