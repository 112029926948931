import {InternalError} from '@/errors/InternalError';
import {UserStatusError} from '@/errors/UserStatusError';
import {ValidationError} from '@/errors/ValidationError';
import {ServiceApolloErrors} from '@/types/serviceErrors';
import {FirebaseError} from 'firebase/app';

import {ApolloError} from '@apollo/client';

import {FBUser} from '@/hooks/useFirebaseUser';
import AnalyticsService, {analytics} from '@/services/tracking/analytics/AnalyticService';
import EventPathMatcher, {MatcherArgs} from '@/services/tracking/analytics/EventPathMatcher';
import {KaLog, kaLog} from '@/services/tracking/kastaLogs/KaLog';

import type {events} from '@/services/tracking/analytics/event';
import type {AnalyticsEvent, ERROR_EVENT_INFO} from '@/types/tracking';

type TrackingEvent = AnalyticsEvent & {
  onlyKastaLog?: boolean;
  onlyTagManagerLog?: boolean;
};

// TODO: Define analytics events permission strategy
export default class TrackingService {
  private readonly kastaLog: KaLog;
  private readonly analytics: AnalyticsService;
  private readonly eventPathMatcher: EventPathMatcher;
  events: typeof events;

  constructor(kastaLog: KaLog, analytics: AnalyticsService) {
    this.kastaLog = kastaLog;
    this.analytics = analytics;
    this.eventPathMatcher = new EventPathMatcher();
    this.events = analytics.events;
  }

  setUser(user: FBUser | null) {
    this.kastaLog.setFirebaseUserId(user);
    this.analytics.setUser(user);
  }

  // To use this logEvent method, you need to pass same event name and params
  // to both analytics and kastaLog
  async logEvent({
    name,
    params,
    idempotencyId,
    onlyKastaLog = false,
    onlyTagManagerLog = false,
  }: TrackingEvent) {
    const {kastaLog, analytics} = this;

    try {
      if (!onlyKastaLog) {
        await analytics.logEvent({name, params, idempotencyId});
      }
      if (!onlyTagManagerLog) {
        await kastaLog.info('ANALYTICS', {
          eventName: name,
          ...params,
        });
      }
    } catch (error: any) {
      await this.logTrackingError(error?.message);
    }
  }

  async logEventBasedOnPath(args: MatcherArgs) {
    try {
      const event = this.eventPathMatcher.match(args);
      if (event) {
        await this.logEvent({name: event.name, params: event.params});
      }
    } catch (error: any) {
      await this.logTrackingError(error?.message);
    }
  }

  async logScreenView() {
    try {
      await this.logEvent({
        onlyKastaLog: true,
        name: 'screen_view',
      });
    } catch (error: any) {
      await this.logTrackingError(error?.message);
    }
  }

  async logError(err: ERROR_EVENT_INFO, onlyKastaLog = false) {
    const {kastaLog, analytics, events} = this;

    if (!onlyKastaLog && window.dataLayer) {
      analytics.logEvent(events.error(err));
    }

    await kastaLog.error(err);
  }

  async logTrackingError(errorMessage: string) {
    await this.logError({
      error_message: errorMessage ?? 'tracking log error',
      error_message_id: 'tracking_log_error',
      error_level: 'Non Critical',
    });
  }

  async logServiceError({error, apolloErrors}: {error: unknown; apolloErrors: ServiceApolloErrors}) {
    if (
      error instanceof ValidationError ||
      error instanceof InternalError ||
      error instanceof UserStatusError
    ) {
      this.logError({
        error_message: error.message,
        error_message_id: error.code,
        error_level: error.level,
      });
    }

    if (error instanceof FirebaseError) {
      this.logError({
        error_message: error.message,
        // @ts-expect-error
        error_message_id: error.code,
        error_level: 'Critical',
      });
    }

    if (error instanceof ApolloError) {
      function getErrorObjForApolloError(msg: string) {
        return Object.values(apolloErrors).find(({MSG}) => MSG === msg);
      }

      const errObj = getErrorObjForApolloError(error.message);
      if (errObj) {
        this.logError({
          error_message: error.message,
          error_message_id: errObj.CODE,
          error_level: errObj.LVL,
        });
      }
    }
  }
}

export const tracking = new TrackingService(kaLog, analytics);
