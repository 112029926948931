import {useCallback, useEffect} from 'react';
import {useRouter} from 'next/router';

import {Currency, GetUserQuery, PlatformType} from '@/graphql/__generated__/graphql';
import {userUpdatedSubscription} from '@/graphql/subscriptions';
import dayjs from 'dayjs';
import {updateProfile} from 'firebase/auth';
import {createContext, useContext} from 'use-context-selector';

import {afterGet} from '@/controllers/user';
import {updateUser} from '@/graphql/mutations';
import {getUser} from '@/graphql/queries';
import {useMutation, useQuery, useSubscription} from '@apollo/client';

import useEntityServerClientMerge from '@/hooks/useEntityServerClientMerge';
import {useZendeskLogin} from '@/hooks/useZendeskLogin';
import {mixPanel} from '@/services/tracking/analytics/MixPanel';
import {tracking} from '@/services/tracking/TrackingService';

import type {SessionStatesProps} from '@/pages/_app';
import type {User} from '@/types/user';
import type {User as FbUser} from 'firebase/auth';
import type {Dispatch, ReactNode, SetStateAction} from 'react';

type UserContextType = {
  user: User | null;
  fetchAndSetUser: () => Promise<User | null>;
  displayCurrency: Currency;
  userLoading: boolean;
  userError: string;
  fbUser: FbUser | null;
  setFBUserOnce: boolean;
  userDidEnterSoftPin: boolean;
  setUserDidEnterSoftPin?: Dispatch<SetStateAction<boolean>>;
  rawUserQuery?: GetUserQuery | null;
  skipUserFetching: boolean;
  userJustSignInOrSignUp: () => boolean;
};

const UserContext = createContext<UserContextType>({
  user: null,
  fetchAndSetUser: async () => null,
  displayCurrency: Currency.Usd,
  userLoading: false,
  userError: '',
  fbUser: null,
  setFBUserOnce: false,
  userDidEnterSoftPin: false,
  rawUserQuery: null,
  skipUserFetching: true,
  userJustSignInOrSignUp: () => false,
});

const signInTimeThreshold = 10;

export const useUser = () => useContext(UserContext);

const UserContextProvider = ({
  children,
  fbUser,
  setFBUserOnce,
  userDidEnterSoftPin,
  setUserDidEnterSoftPin,
}: SessionStatesProps & {
  children: ReactNode;
}) => {
  const {pathname, query} = useRouter();
  const {justSignedUp} = query;

  // In signup/login pages, user will be fetched manually when we're certain that the USER# record exists in our database
  const skipUserFetching =
    ['/login', '/signup'].some(subpath => pathname.includes(subpath)) || !fbUser || fbUser.isAnonymous;

  const {
    data: userQueryData,
    refetch: refetchUser,
    error: queryError,
    loading: queryLoading,
  } = useQuery(getUser, {skip: skipUserFetching});

  const userJustSignInOrSignUp = useCallback(() => {
    if (justSignedUp) {
      return true;
    }

    if (!fbUser?.metadata) {
      return false;
    }

    const lastSignInTime = dayjs(fbUser?.metadata?.lastSignInTime);
    const diff = dayjs().diff(lastSignInTime, 'second');

    return diff < signInTimeThreshold;
  }, [fbUser, justSignedUp]);

  useEffect(() => {
    // @ts-expect-error - graphQLErrors errorType not in ApolloDefinition
    const graphQlerrorType = queryError?.graphQLErrors?.[0]?.errorType ?? '';
    // @ts-expect-error - networkError statusCode not in ApolloDefinition
    const networkErrorType = queryError?.networkError?.statusCode;
    if (graphQlerrorType === 'Unauthorized' || networkErrorType === 401) {
      refetchUser();
    }
  }, [queryError, refetchUser]);

  const {
    data: subData,
    error: subError,
    loading: subLoading,
  } = useSubscription(userUpdatedSubscription, {skip: skipUserFetching});

  const [user, fetchAndSetUser] = useEntityServerClientMerge<User | null>({
    hydratatedData: userQueryData,
    subData,
    afterGet,
    lazyQuery: refetchUser,
  });

  const [updateUserMutation] = useMutation(updateUser);
  useEffect(() => {
    async function updatePlatform() {
      const userPlatformUpdated = sessionStorage.getItem('userPlatformUpdated');
      if (user && !userPlatformUpdated) {
        try {
          await updateUserMutation({variables: {input: {latestPlatformUsed: PlatformType.Pwa}}});
          sessionStorage.setItem('userPlatformUpdated', 'true');
        } catch (error) {
          // failing because async data between fbUser id and user id storaged
          // it will work when user updates
          tracking.logError({
            error_message: (error as any)?.message || JSON.stringify(error),
            error_level: 'Non Critical',
            error_message_id: 'kasta_log_error',
          });
        }
      }
    }
    updatePlatform().then();
  }, [user, updateUserMutation]);

  // TODO: update when this is implemented at user settings
  const displayCurrency = Currency.Usd;

  useZendeskLogin({
    kastaUser: user,
    fbUser,
  });

  useEffect(() => {
    if (!user || !fbUser || fbUser.isAnonymous) {
      return;
    }

    if (!fbUser.displayName) {
      updateProfile(fbUser, {
        displayName: `${user.firstName} ${user.lastName}`,
      });
    }
    if (user) {
      // About Identify https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#identify
      mixPanel?.identify(user.id);

      mixPanel?.set({
        $name: `${user.firstName} ${user.lastName}`,
        $email: user?.email,
        cashAccountStatus: user?.cashAccountStatus,
        countryCode: user?.address?.countryCode,
      });
    }
  });

  return (
    <UserContext.Provider
      value={{
        user,
        fetchAndSetUser,
        displayCurrency,
        userLoading: queryLoading,
        userError: subError?.message || '',
        fbUser,
        setFBUserOnce,
        userDidEnterSoftPin,
        setUserDidEnterSoftPin,
        rawUserQuery: userQueryData,
        skipUserFetching,
        userJustSignInOrSignUp,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
